<template>
    <div>
        <h1>C-Wurf</h1>
        <h3 style="margin-bottom: 64px">19.08.2024</h3>

        <div class="image">
             <img src="../../assets/img/C-Wurf/C-Wurf-Welpen.jpeg" alt="C-Wurf">
        </div>

        <DogParents :mom="mom" :dad="dad"/>

        <div class="puppies">
            <div class="puppy">
                <h2>Herr Orange</h2>
                <h4>geb. 19.08.2024 7:30 Uhr, 466g, Kennfarbe orange, Rüde</h4>
                <ImageCard :images="orange"/>
            </div>
            <div class="puppy">
                <h2>Frau Grün</h2>
                <h4>geb. 19.08.2024 7:42 Uhr, 437g, Kennfarbe grün, Hündin</h4>
                <ImageCard :images="green"/>
            </div>
            <div class="puppy">
                <h2>Herr Blau</h2>
                <h4>geb. 19.08.2024 8:02 Uhr, 364g, Kennfarbe blau, Rüde</h4>
                <ImageCard :images="blau"/>
            </div>
            <div class="puppy">
                <h2>Herr Rot</h2>
                <h4>geb. 19.08.2024 9:04 Uhr, 444g, Kennfarbe rot, Rüde</h4>
                <ImageCard :images="red"/>
            </div>
            <div class="puppy">
                <h2>Frau Rosa</h2>
                <h4>geb. 19.08.2024 9:35 Uhr, 376g, Kennfarbe rosa, Hündin</h4>
                <ImageCard :images="rosa"/>
            </div>
        </div>
    </div>
</template>

<script>
import ImageCard from '../../components/ImageCard.vue';
import DogParents from '../../components/DogParents.vue';

export default {
  name: 'App',
  data() {
    return {
        mom: {
            name: "Arya vom Gonsbachtal",
            bday: "geb.01.01.2022",
            img_face: require("../../assets/img/Arya_Face_Small.jpg"),
            img_side: require("../../assets/img/Arya_Side.jpg")
        },
        dad: {
            name: "Duke vom Arenholzer See",
            bday: "geb. 16.09.2019",
            img_face: require("../../assets/img/Duke_Face.jpeg"),
            img_side: require("../../assets/img/Duke_Side.jpg")
        },
        orange: {
            woche1: require("../../assets/img/C-Wurf/Woche1/orange.jpg"),
            abgabe: require("../../assets/img/comingSoon.jpg"),
            halbesJahr: require("../../assets/img/comingSoon.jpg"),
            jahr: require("../../assets/img/comingSoon.jpg"),
        },
        green: {
            woche1: require("../../assets/img/C-Wurf/Woche1/green.jpg"),
            abgabe: require("../../assets/img/comingSoon.jpg"),
            halbesJahr: require("../../assets/img/comingSoon.jpg"),
            jahr: require("../../assets/img/comingSoon.jpg"),
        },
        blau: {
            woche1: require("../../assets/img/C-Wurf/Woche1/blau.jpg"),
            abgabe: require("../../assets/img/comingSoon.jpg"),
            halbesJahr: require("../../assets/img/comingSoon.jpg"),
            jahr: require("../../assets/img/comingSoon.jpg"),
        },
        red: {
            woche1: require("../../assets/img/C-Wurf/Woche1/red.jpg"),
            abgabe: require("../../assets/img/comingSoon.jpg"),
            halbesJahr: require("../../assets/img/comingSoon.jpg"),
            jahr: require("../../assets/img/comingSoon.jpg"),
        },
        rosa: {
            woche1: require("../../assets/img/C-Wurf/Woche1/rosa.jpg"),
            abgabe: require("../../assets/img/comingSoon.jpg"),
            halbesJahr: require("../../assets/img/comingSoon.jpg"),
            jahr: require("../../assets/img/comingSoon.jpg"),
        },
    }
  },
  components: {
    ImageCard,
    DogParents
    },
    props: ["onZuchtstaette"],
    emits: ["update-zuchtstaette-nav-on", "update-zuchtstaette-nav-off"],
    mounted() {
        this.$emit("update-zuchtstaette-nav-on")
    },
    unmounted() {
        this.$emit("update-zuchtstaette-nav-off")
    }
}
</script>

<style scoped>
:root {
  --backgroundColor: #dcdece;
  --mainText: #333D29;
  --mainTextLight: #70875A;
  --linkText: #936639;
  --focusedText: #582F0E;
  --higlightedText: #04A0A2;
}

h1 {
    margin-bottom: 0px;
}

h3 {
    color: var(--mainTextLight);
}

h4 {
    color: var(--mainTextLight);
    margin-bottom: 16px;
}

h2 {
    margin-bottom: 0px;
}

.image {
    display: grid;
    justify-content: center;
    align-items: center;
}

img {
    max-width: 750px;
    width: 100%;
    height: auto;
    margin-bottom: 32px;
    border-radius: 8px;
}

.puppies {
    display: grid;
    gap: 64px 0px;
}

h4 {
    display: flex;
}

i {
    color: var(--mainTextLight);
    font-size: 0.8em;
}
</style>